import { lazy } from "react";

// use lazy for better code splitting
const Dashboard = lazy(() => import("../pages/admin/dashboard"));
const Offers = lazy(() => import("../pages/admin/offers"));
const School = lazy(() => import("../pages/admin/school"));
const Students = lazy(() => import("../pages/admin/students"));
const Company = lazy(() => import("../pages/admin/company"));
const Pairing = lazy(() => import("../pages/admin/pairing"));
const SchoolProgram = lazy(() => import("../pages/admin/schoolProgram"));
const Notifications = lazy(() => import("../pages/admin/notifications"));
const Faqs = lazy(() => import("../pages/admin/faqs"));
const Events = lazy(() => import("../pages/admin/events"));
const eventDetails = lazy(() => import("../components/infos/eventDetails"));
const Resources = lazy(() => import("../pages/admin/resource"));
const Profile = lazy(() => import("../pages/admin/profile"));
const AddStudentStep1 = lazy(() =>
  import("../pages/admin/addStudent/AddStudentStep1")
);
const AddStudentStep2 = lazy(() =>
  import("../pages/admin/addStudent/AddStudentStep2")
);
const AddStudentStep3 = lazy(() =>
  import("../pages/admin/addStudent/AddStudentStep3")
);
const AddCompanyStep1 = lazy(() =>
  import("../pages/admin/addCompany/AddCompanyStep1")
);
const AddCompanyStep2 = lazy(() =>
  import("../pages/admin/addCompany/AddCompanyStep2")
);
const AddCompanyStep3 = lazy(() =>
  import("../pages/admin/addCompany/AddCompanyStep3")
);
const CompanyInfo = lazy(() => import("../components/infos/CompanyInfo"));
const StudentInfo = lazy(() => import("../components/infos/StudentInfo"));
const offerDetail = lazy(() => import("../components/infos/offerDetail"));
const UpdateStudent = lazy(() =>
  import("../pages/admin/addStudent/UpdateStudent")
);
const AppliedCandidates = lazy(() =>
  import("../pages/admin/AppliedCandidates")
);

/*
//  * ⚠ These are internal routes!
//  * They will be rendered inside the app, using the default `containers/LayoutAdmin`.
//  * If you want to add a route to, let's say, a landing page, you should add
//  * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
//  * are routed.
//  *
//  * If you're looking for the links rendered in the SidebarContent, go to
//  * `routes/sidebarAdmin.js`
 */

const routesAdmin = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/student",
    component: Students,
    exact: true,
    name: "student",
  },
  {
    path: "/admin/student/:id",
    component: StudentInfo,
  },
  {
    path: "/student/add",
    component: AddStudentStep1,
    exact: true,
    name: "addStudent",
  },
  {
    path: "/student/add/step_2",
    component: AddStudentStep2,
    exact: true,
    name: "addStudentStep2",
  },
  {
    path: "/student/add/step_3",
    component: AddStudentStep3,
    exact: true,
    name: "addStudentStep3",
  },
  {
    path: "/student/edit/:id",
    component: UpdateStudent,
    exact: true,
    name: "updateStudent",
  },
  {
    path: "/company",
    component: Company,
  },
  {
    path: "/admin/company/:id",
    component: CompanyInfo,
  },
  {
    path: "/company/add",
    component: AddCompanyStep1,
    exact: true,
    name: "addCompanyStep1",
  },
  {
    path: "/company/add/step_2",
    component: AddCompanyStep2,
    exact: true,
    name: "addCompanyStep2",
  },
  {
    path: "/company/add/step_3",
    component: AddCompanyStep3,
    exact: true,
    name: "addCompanyStep3",
  },
  {
    path: "/pairing",
    component: Pairing,
  },
  {
    path: "/offer",
    component: Offers,
  },
  {
    path: "/admin/offer/:id",
    component: offerDetail,
  },
  {
    path: "/admin/applied/:id",
    component: AppliedCandidates,
  },
  {
    path: "/school",
    component: School,
  },
  {
    path: "/schoolProgram",
    component: SchoolProgram,
  },
  {
    path: "/notifications",
    component: Notifications,
  },
  {
    path: "/events",
    component: Events,
  },
  {
    path: "/event/:id",
    component: eventDetails,
  },
  {
    path: "/faqs",
    component: Faqs,
  },
  {
    path: "/resource",
    component: Resources,
  },
  {
    path: "/profile",
    component: Profile,
  },
];

export default routesAdmin;
