import { GET_STUDENT_PERCENTAGE } from "../Constants/StudentPercentageConstant";

const initialState = {
  studentProfilePercent: null,
};

export const getStudentPercentage = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_PERCENTAGE:
      return {
        ...state,
        studentProfilePercent: action.studentProfilePercent,
      };

    default:
      return state;
  }
};
