import React from "react";
import CompanyRegForm2 from "../../../components/signupForm/companyRegForm/CompanyRegForm2";
import Goback from "../../../components/form/Goback";
import Image from "../../../assets/res/companiesregistration.png";
import { useTranslation } from "react-i18next";

const CompReg2 = () => {
  const { t } = useTranslation();
  return (
    <div className="page_cont">
      <div className="flex-1 flex justify-center items-center w-full md:w-1/2">
        <div className="w-full max-w-lg px-4">
          <Goback link={"/register/company"} />
          <div className="flex flex-col mb-5">
            <h3 className="text-2xl text-dark font-bold">
              {t("signUp.register.title")}
            </h3>
            <p className="text-parag text-sm">
              {t("signUp.register.description")}
            </p>
          </div>
          <CompanyRegForm2 />
        </div>
      </div>
      <div className="flex-1 hidden md:block w-full md:w-1/2">
        <img
          src={Image}
          alt="Register"
          className="w-full h-screen object-center"
        />
      </div>
    </div>
  );
};

export default CompReg2;
