import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import {ADMIN, LANGUAGE, USER} from "../../pref";
import * as yup from "yup";
import { useFormik } from "formik";
import { SidebarContext } from "../../context/SidebarContext";
import AdminServices from "../../services/AuthServices";
import Cookies from "js-cookie";

const useLoginSubmit = () => {
  const { dispatch } = useContext(AdminContext);
  const navigate = useNavigate();
  const currentLanguageCode = Cookies.get(LANGUAGE) || "fr";
  const { handleLanguageChange } = useContext(SidebarContext);

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => setShowPassword((old) => !old);
  const removeError = () => setError("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = yup.object({
    email: yup.string().email().required("Veuillez entrer votre email"),
    password: yup.string().required("Veuillez entrer votre mot de passe"),
  });

  const onSubmit = (e) => {
    setLoading(true);
    const cookieTimeOut = 0.5;
    AdminServices.login(e)
      .then((res) => {
        if (res) {
          console.log(res);
          setLoading(false);
          delete res.data["notifications"];
          dispatch({ type: "USER_LOGIN", payload: res.data.data });
          Cookies.set(USER, JSON.stringify(res.data.data), {
            expires: cookieTimeOut,
          });
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err ? err?.response?.data?.message : err?.message);
        console.log(error);
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    showPassword,
    togglePassword,
    currentLanguageCode,
    handleLanguageChange,
    error,
    removeError,
    loading,
  };
};

export default useLoginSubmit;
