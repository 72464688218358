import requests from "./httpService";

const ProgramsServices = {
  getAllPrograms: async () => {
    return requests.get(`${process.env.REACT_APP_BASE_API_VERSION}programs`);
  },

  deleteProgram: async (id) => {
    return requests.delete(
      `${process.env.REACT_APP_BASE_API_VERSION}programs/${id}`
    );
  },

  addProgram: async (body) => {
    return requests.post(
      `${process.env.REACT_APP_BASE_API_VERSION}programs`,
      body
    );
  },

  updateProgram: async (body) => {
    return requests.put(
      `${process.env.REACT_APP_BASE_API_VERSION}programs/${body.id}`,
      body.data
    );
  },
};

export default ProgramsServices;
