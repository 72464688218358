import Cookies from "js-cookie";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoMenu } from "react-icons/io5";
import { FaBars } from "react-icons/fa";
//internal import
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import fr from "../../../assets/img/fr.svg";
import en from "../../../assets/img/us.svg";
import { AdminContext } from "../../../context/AdminContext";
import { SidebarContext } from "../../../context/SidebarContext";
import { emptySetting } from "../../../redux/Actions/SettingActions";
import { emptySideBarMenu } from "../../../redux/Actions/SideBarActions";
import {LANGUAGE} from "../../../pref";

const Header = () => {
  const reduxDisPatch = useDispatch();
  const { toggleSidebar, handleLanguageChange, setNavBar, navBar } =
    useContext(SidebarContext);
  const { state, dispatch } = useContext(AdminContext);
  const { adminInfo } = state;
  const [profileOpen, setProfileOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const pRef = useRef();
  const nRef = useRef();

  const currentLanguageCode = cookies.get(LANGUAGE) || "en";
  const { t } = useTranslation();

  const handleLogOut = () => {
    dispatch({ type: "USER_LOGOUT" });
    Cookies.remove("adminInfo");
    reduxDisPatch(emptySideBarMenu());
    reduxDisPatch(emptySetting());
    window.location.replace(
      `https://${process.env.REACT_APP_ADMIN_DOMAIN}/login`
    );
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!pRef?.current?.contains(e.target)) {
        setProfileOpen(false);
      }
      if (!nRef?.current?.contains(e.target)) {
        setNotificationOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [pRef, nRef]);

  return (
    <>
      <header className="z-30 py-4 bg-white shadow-sm dark:bg-gray-800 px-4">
        <div className="container flex items-center justify-between h-full mx-auto text-green-500 dark:text-green-500">
          <div className="justify-center items-center gap-2.5 hidden lg:flex ">
            <button
              type="button"
              onClick={() => setNavBar(!navBar)}
              className="outline-0 focus:outline-none"
            >
              <FaBars className="text-primary" />
            </button>
            <div className="text-primary font-bold">{t("welcome")}</div>
          </div>

          {/* <!-- Mobile hamburger --> */}
          <button
            className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none"
            onClick={toggleSidebar}
            aria-label="Menu"
          >
            <FaBars className="text-primary" />
          </button>
          <span></span>

          <ul className="flex justify-end items-center flex-shrink-0 space-x-6">
            <li className="changeLanguage">
              <div className="dropdown">
                <button className="dropbtn focus:outline-none">
                  {currentLanguageCode === "fr" ? (
                    <img src={fr} width={16} alt="lang" className="mx-2" />
                  ) : (
                    <img src={en} className="mx-2" alt="lang" width={16} />
                  )}
                  {currentLanguageCode === "fr" ? "FRENCH" : "ENGLISH"}
                </button>

                <div className="dropdown-content">
                  <div
                    onClick={() => handleLanguageChange("fr")}
                    className="focus:outline-none cursor-pointer"
                  >
                    <img src={fr} width={16} alt="lang" /> FRENCH
                  </div>
                  <div
                    onClick={() => handleLanguageChange("en")}
                    className="focus:outline-none cursor-pointer"
                  >
                    <img src={en} width={16} alt="lang" /> English{" "}
                  </div>
                </div>
              </div>
            </li>

            {/* <!-- Theme toggler --> */}

            {/*<li className="flex">*/}
            {/*  <button*/}
            {/*    className="rounded-md focus:outline-none"*/}
            {/*    onClick={toggleMode}*/}
            {/*    aria-label="Toggle color mode"*/}
            {/*  >*/}
            {/*    {mode === "dark" ? (*/}
            {/*      <IoSunny className="w-5 h-5" aria-hidden="true" />*/}
            {/*    ) : (*/}
            {/*      <IoMoonSharp className="w-5 h-5" aria-hidden="true" />*/}
            {/*    )}*/}
            {/*  </button>*/}
            {/*</li>*/}

            {/* <!-- Profile menu --> */}
            <li className="relative inline-block text-left" ref={pRef}>
              <button
                className="rounded-full bg-primary text-white flex justify-center items-center h-8 w-8 font-medium mx-auto focus:outline-none"
                onClick={() => console.log("clicked on profile")}
              >
                {adminInfo?.picturePath ? (
                  <img
                    className="align-middle w-full h-full rounded-full object-cover"
                    src={`${process.env.REACT_APP_BASE_API_URL}media/events?path=${adminInfo.picturePath}`}
                    aria-hidden="true"
                    alt="image"
                  />
                ) : (
                  <div className="w-fit h-fit uppercaseh text-lg">
                    c{/*{adminInfo?.email[0]}*/}
                  </div>
                )}
              </button>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
