import logo from "../../assets/ico/logo.png"
import facebok from "../../assets/res/facebook.png";
import instagram from "../../assets/res/instagram.png";
import youtube from "../../assets/res/youtube.png";
import copyright from "../../assets/res/copyright.png";
import {useTranslation} from "react-i18next";
import useHome from "../../hooks/useHome";

const Footer = () => {
    const { t } = useTranslation();

    const {
        adminInfo,
        showMobileNav,
        handleShow,
        openPage,
        currentLanguageCode,
        handleLanguageChange
    } = useHome();

    return (
        <>
            <div className="bg-primary py-8 flex justify-center items-center">
                <div
                    className="w-full h-fit flex flex-col items-start max-w-[1500px] justify-center lg:items-center lg:mx-7 mx-3">
                    <div
                        className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-10 lg:items-start lg:mx-3">
                        <div className="">
                            <div className="w-48 ">
                                <a href="/">
                                    <img
                                        src={logo}
                                        alt="Picture of the author"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 md:gap-6">
                            <span className="text-white text-lg font-semibold">
                                 {t("home.footer.company")}
                            </span>
                            <div className="flex flex-col gap-2 font-light">
                                <a target="_blank" href="#">
                                    <span className="text-white hover:underline">
                                        {t("home.footer.useCondition")}
                                    </span>
                                </a>
                                <a href="https://ccnb.ca/le-ccnb/politiques-du-ccnb/">
                                    <span className="text-white hover:underline">
                                        {t("home.footer.policyPrivacy")}
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 md:gap-6">
                           <span className="text-white text-lg font-semibold">
                                {t("home.footer.joinUs")}
                            </span>
                            <div className="flex flex-col gap-2 font-light">
                                <a target="_blank"
                                   href="mailto:info@ccnb.ca">
                                    <span className="text-white hover:underline">info@ccnb.ca</span>
                                </a>
                                <a target="_blank"
                                   href="https://ccnb.ca/services-aux-etudiants/orientation-et-counselling/">
                                    <span className="text-white hover:underline">
                                         {t("home.footer.contact")}
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 md:gap-6 text-lg ">
                            <span className="text-white font-semibold">
                                {t("home.footer.followUs")}
                            </span>
                            <div className="w-24 gap-4 flex flex-row">
                                <a target="_blank"
                                   href="https://www.facebook.com/CCNB.officielle/?__cft__[0]=AZXFXxWp9nl6cfHSLlke00WH9E27piaGnzTCYEvz4HeNEc-HXJst1W_ACrtRGfLubTrcYIA7BoO0ggO19nKL7GX--Z4mI1h598e_uDCc76dlOQcOEXXVVtPPFUjWXyWn7mSTZsaMC-DkEzQYDzyugH_xOwwJDREBqeOOyCno8u4ydU4CetFd2GyjzkuyjMykSms&__tn__=-UC%2CP-R"><img
                                    src={facebok} alt="facebook"/></a>
                                <a target="_blank" href="https://instagram.com/ccnbofficiel?igshid=YmMyMTA2M2Y="><img
                                    src={instagram} alt="instagram"/></a>
                                <a target="_blank" href="https://www.youtube.com/@CCNBOfficiel">
                                    <img src={youtube} alt="youtube"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="border border-white w-full mt-6"/>
                    <div className="mt-6 w-full flex flex-row justify-center items-center gap-3">
                        <div className="flex-none w-3">
                            <img src={copyright} alt="copyright" layout="responsive"/>
                        </div>
                        <p className="text-white ">2023 Jumelage CCNB, {" "}
                            {t("home.footer.allRight")}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;
