import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import "./index.css";
import "./assets/css/custom.css";
import "./assets/css/tailwind.css";
import "./assets/css/tailwind.output.css";
import App from "./App";
import { AdminProvider } from "./context/AdminContext";
import { SidebarProvider } from "./context/SidebarContext";
import { Provider } from "react-redux";
import store from "./redux/Store";
import ThemeSuspense from "./components/theme/ThemeSuspense";
import { ThemeProvider } from "@mui/material";
import { theme } from "./components/theme/Theme";

// for react skeleton
import "react-loading-skeleton/dist/skeleton.css";

// for react circular progressbar
import "react-circular-progressbar/dist/styles.css";

// for toast messages
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AdminProvider>
        <SidebarProvider>
          <Provider store={store}>
            <Suspense fallback={<ThemeSuspense />}>
              <App />
            </Suspense>
          </Provider>
        </SidebarProvider>
      </AdminProvider>
    </ThemeProvider>
  </React.StrictMode>
);
