import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { settingReducers } from "./Reducers/SettingReducers";
import { addToSideBarMenuReducer } from "./Reducers/SideBarMenuReducers";
import { StudentAddReducers } from "./Reducers/StudentRegisterReducer";
import { CompanyAddReducers } from "./Reducers/CompanyRegisterReducer";
import { UserReducers } from "./Reducers/UserReducer";
import { OfferAddReducers } from "./Reducers/OfferAddReducers";
import { getStudentPercentage } from "./Reducers/StudentPercentageReducers";

const reducer = combineReducers({
  addToSideBar: addToSideBarMenuReducer,
  setting: settingReducers,
  addStudent: StudentAddReducers,
  addCompany: CompanyAddReducers,
  userInfo: UserReducers,
  addOffer: OfferAddReducers,
  getStudentPercent: getStudentPercentage,
});

const initialState = {
  addToSideBar: {
    sideBarAdd: JSON.parse(localStorage.getItem("_sideBarAdd"))
      ? JSON.parse(localStorage.getItem("_sideBarAdd"))
      : {},
  },

  setting: {
    settingItem: JSON.parse(localStorage.getItem("_settingItem"))
      ? JSON.parse(localStorage.getItem("_settingItem"))
      : [],
  },
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
