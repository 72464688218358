import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { COMPANY_REGISTER_STEP_2 } from "../../redux/Constants/CompanyRegisterConstant";
import useAsync from "../useAsync";
import ProgramsServices from "../../services/ProgramServices";
import AdminServices from "../../services/AuthServices";

const useCompanyRegStep2 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  // const companyInfo = useSelector((state) => state.addCompany);

  // url param init
  const [searchParams, setSearchParams] = useSearchParams({
    logo: "",
    schoolProgram: "",
    refName: "",
    refEmail: "",
    refPhoneNumber: "",
  });
  const logo = searchParams.get("logo");

  const schoolProgram = searchParams.get("schoolProgram");
  const refName = searchParams.get("refName");
  const refEmail = searchParams.get("refEmail");
  const refPhoneNumber = searchParams.get("refPhoneNumber");

  console.log("schoolProgram ", schoolProgram);

  // for store let schoolProgram in url
  let schoolProgramUrl = "";

  // formik initial values
  const initialValues = {
    logo: logo || "",
    schoolProgram: schoolProgram || "",
    refPerson: {
      refName: refName || "",
      refEmail: refEmail || "",
      refPhoneNumber: refPhoneNumber || "",
    },
  };

  // formik validation
  const validationSchema = yup.object({
    logo: yup
      .mixed()
      .test("FILE_SIZE", "Image must be less than 5MB", function (value) {
        if (value === "undefined" || value)
          return value && value.size <= 1024 * 1024 * 5;
        else {
          return true;
        }
      })
      .test("FILE_TYPE", "Image must be JPEG, PNG or JPG", function (value) {
        if (value === "undefined" || value)
          return (
            value &&
            (value.type === "image/jpg" ||
              value.type === "image/jpeg" ||
              value.type === "image/png")
          );
        else {
          return true;
        }
      })
      .nullable(),
    refPerson: yup.object().shape({
      refName: yup
        .string()
        .required(t("signUp.enterprise.validationMessage.responsibleName")),
      refEmail: yup
        .string()
        .email()
        .required(t("signUp.enterprise.validationMessage.responsibleEmail")),
      refPhoneNumber: yup
        .number()
        .required(t("signUp.enterprise.validationMessage.phoneNumber")),
    }),
    schoolProgram: yup
      .string()
      .required(t("signUp.student.validationMessage.schoolProgram")),
  });

  // formik submit
  const onSubmit = (e) => {
    const data = { ...e, phoneNumber: `+1${e.refPerson.refPhoneNumber}` };
    setSearchParams(
      (prev) => {
        prev.set("logo", formik.values.logo);
        prev.set("schoolProgram", formik.values.schoolProgram);
        prev.set("refPerson", formik.values.refPerson);
        prev.set("refName", formik.values.refPerson.refName);
        prev.set("refEmail", formik.values.refPerson.refEmail);
        prev.set("refPhoneNumber", formik.values.refPerson.refPhoneNumber);

        return prev;
      },
      { replace: true }
    );
    setLoading(true);
    AdminServices.verifyEmail(formik.values.refPerson.refEmail)
      .then((res) => {
        setLoading(false);
        if (res.data === true) {
          setErrors("Sorry! Email already exist...");
        } else {
          dispatch({ type: COMPANY_REGISTER_STEP_2, data });
          navigate("/register/company/step_3");
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err ? err?.response?.data?.message : err?.message);
      });
  };

  // formik init
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    loading: loadingProgram,
    data: programsData,
    error,
  } = useAsync(ProgramsServices.getAllPrograms);

  // form school program
  const [schoolProgramValue, setSchoolProgramValue] = useState(null);
  const [schoolProgramData, setSchoolProgramData] = useState([]);
  const handleSchoolProgramChange = (value) => {
    console.log(value);
    setSchoolProgramValue(value);
  };

  useEffect(() => {
    if (programsData?.data) {
      setSchoolProgramData(
        programsData.data.map((item, i) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [programsData]);

  // formik retrieve school value when select
  useEffect(() => {
    schoolProgramValue?.map((item) => {
      schoolProgramUrl =
        schoolProgramUrl === ""
          ? item.value
          : schoolProgramUrl + "," + item.value;
    });
    // console.log(schoolProgramUrl)
    formik.setFieldValue(
      "schoolProgram",
      schoolProgramValue === null ? "" : schoolProgramUrl
    );
  }, [schoolProgramValue]);

  // retrieve school program
  useEffect(() => {
    // console.log("AAAAAAAA")
    // console.log(schoolProgram.split(","))
    const schoolProgramUrl = programsData?.data?.filter((item) => {
      let programsDataElement;
      schoolProgram.split(",").map((id) => {
        if (item.id === parseInt(id)) {
          programsDataElement = item;
        }
      });

      return programsDataElement;
    });

    // console.log(schoolProgramUrl)

    handleSchoolProgramChange(
      schoolProgramUrl?.map((item, i) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  }, [schoolProgram !== "" && schoolProgram !== null && programsData?.data]);

  return {
    formik,
    schoolProgramValue,
    schoolProgramData,
    handleSchoolProgramChange,
    errors,
    loading,
  };
};

export default useCompanyRegStep2;
