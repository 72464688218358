import React, { useContext } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import {AdminContext} from "../../context/AdminContext";

const PrivateRoute = () => {
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;

  return (
      adminInfo?.accessToken ? <Outlet/> : <Navigate to="/home"/>
  );
};

export default PrivateRoute;
