import {
  ImHome
} from "react-icons/im"

import {
  BsBriefcaseFill
} from "react-icons/bs"

import {
  IoIosPaper,
  IoMdSettings
} from "react-icons/io"

import {
  MdNotifications
} from "react-icons/md"

import {
  BsBuildingFill,
  BsFillCalendar3WeekFill,
  BsFillQuestionCircleFill
} from "react-icons/bs"

import {
  FaUser,
  FaUserTie,
  FaHandsHelping
} from "react-icons/fa"

import {
  FaChalkboardUser
} from "react-icons/fa6"

/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/routeAdmin.js`
 */
const sidebarAdmin = [
  {
    path: "/", // the url
    icon: ImHome, // icon
    name: "nav.admin.dashboard", // name that appear in Sidebar
  },
  {
    path: "/student", // the url
    icon: FaUser, // icon
    name: "nav.admin.student", // name that appear in Sidebar
  },
  {
    path: "/company", // the url
    icon: FaUserTie, // icon
    name: "nav.admin.company", // name that appear in Sidebar
  },
  {
    path: "/pairing", // the url
    icon: FaHandsHelping, // icon
    name: "nav.admin.pairing", // name that appear in Sidebar
  },
  {
    path: "/offer", // the url
    icon: BsBriefcaseFill, // icon
    name: "nav.admin.offer", // name that appear in Sidebar
  },
  {
    path: "/school", // the url
    icon: BsBuildingFill, // icon
    name: "nav.admin.school", // name that appear in Sidebar
  },
  {
    path: "/schoolProgram", // the url
    icon: FaChalkboardUser, // icon
    name: "nav.admin.schoolProgram", // name that appear in Sidebar
  },
  {
    path: "/notifications", // the url
    icon: MdNotifications, // icon
    name: "nav.admin.notifications", // name that appear in Sidebar
  },
  {
    path: "/events", // the url
    icon: BsFillCalendar3WeekFill, // icon
    name: "nav.admin.events", // name that appear in Sidebar
  },
  {
    path: "/faqs", // the url
    icon: BsFillQuestionCircleFill, // icon
    name: "nav.admin.faqs", // name that appear in Sidebar
  },
  {
    path: "/resource", // the url
    icon: IoIosPaper, // icon
    name: "nav.admin.resource", // name that appear in Sidebar
  },
  {
    path: "/profile", // the url
    icon: IoMdSettings, // icon
    name: "nav.admin.profile", // name that appear in Sidebar
  },
];

export default sidebarAdmin;
