import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { REGISTER_STUDENT_STEP_3 } from "../../redux/Constants/StudentRegisterConstant";
import AdminServices from "../../services/AuthServices";

const useStudentRegStep3 = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const togglePassword = () => setShowPassword((old) => !old);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPassword = () => setShowConfirmPassword((old) => !old);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isError, setIsError] = useState(false);

  const studentInfo = useSelector((state) => state.addStudent);

  const initialValues = {
    password: "",
  };
  const validationSchema = yup.object({
    password: yup
      .string()
      .min(6, "Password must have atleast 6 characters")
      .required(t("signUp.student.validationMessage.password")),
  });
  const onSubmit = (e) => {
    setLoading(true);
    if (confirmPassword === e.password) {
      // console.log({ studentInfo });
      // dispatch({ type: REGISTER_STUDENT_STEP_3, password: e.password });
      setTimeout(() => {
        AdminServices.registerStudent(studentInfo)
          .then((res) => {
            setLoading(false);
            const formData = new FormData();
            formData.append("picture", studentInfo.photo);
            formData.append("userId", res.data.id);
            AdminServices.uploadStudentImage({
              id: res.data.id,
              picture: formData,
            })
              .then((res) => {
                console.log(`Result of image upload`);
                console.log(res);
              })
              .catch((err) => {
                console.log(`Error due to image upload `);
                console.log(err);
              });
            navigate("/");
          })
          .catch((err) => {
            setLoading(false);
            setError(err ? err?.response?.data?.message : err?.message);
          });
      }, 1000);
    } else {
      setIsError(true);
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    isError,
    showPassword,
    togglePassword,
    toggleConfirmPassword,
    showConfirmPassword,
    confirmPassword,
    setConfirmPassword,
    setIsError,
    loading,
    error,
  };
};

export default useStudentRegStep3;
