import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../components/home/header";
import useHome from "../hooks/useHome";

// icons
import { MdOutlineArrowForward } from "react-icons/md";

// assets
import newbrun from "../assets/res/newbrunshw.png";
import ccnb from "../assets/res/ccnb.png";
import conseil from "../assets/res/conseileco.png";
import onb from "../assets/res/onb.png";
import workin from "../assets/res/working.png";
import compte from "../assets/res/compte.png";
import cv from "../assets/res/cv.png";
import emploiemploi from "../assets/res/emploi.png";
import stephanie from "../assets/res/stephanie.png";
import participants from "../assets/res/participants.png";
import headerIllustration from "../assets/res/header.png";
import aboutIllustration from "../assets/res/header1.png";
import Footer from "../components/home/footer";

const Home = () => {
  const { t } = useTranslation();

  const { adminInfo, showMobileNav, handleShow, openPage } = useHome();

  return (
    <div className="flex flex-col text-black">
      {/* Header */}
      <div className="w-full sticky top-0 z-20 bg-primary ">
        <Header />
      </div>

      {/* Section 1  */}
      <div className="bg-primary flex justify-center h-full w-full lg:py-36">
        <div className="max-w-[1500px] justify-center items-center mx-auto flex flex-col lg:flex-row gap-6 mt-4 lg:mt-0 pb-6 lg:pb-0 px-6 text-white">
          <div className="flex-1 flex flex-col gap-4">
            <p className="md:text-base lg:text-lg xl:text-xl mt-4 font-medium">
              <span className="capitalize">Jumelage CCNB</span>{" "}
              {t("home.section1.title")}
            </p>
            <p className="md:text-base lg:text-lg xl:text-xl mt-4 font-medium">
              {t("home.section1.description")}
            </p>
            <div className="flex flex-row gap-4 mt-3 md:flex md:flex-row">
              {adminInfo !== null ? (
                <>
                  <Link to={`/register`} className="btn-secondary-sm">
                    <span>{t("home.section1.start")}</span>
                    <MdOutlineArrowForward className="ml-2 w-4 h-4 text-primary" />
                  </Link>
                  <a
                    target="_blank"
                    className="btn-primary-2"
                    rel="noreferrer"
                    href="https://ccnb.ca/services-aux-etudiants/orientation-et-counselling/"
                  >
                    {t("home.section1.contact")}
                  </a>
                </>
              ) : (
                <>
                  <Link to="/register" className="btn-secondary-sm">
                    <span>{t("home.section1.start")}</span>
                    <MdOutlineArrowForward className="ml-2 w-4 h-4 text-primary" />
                  </Link>
                </>
              )}
            </div>
          </div>
          <div className="flex-1 mt-8 mx-8 w-full">
            <img src={headerIllustration} alt="header illustration" />
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="bg-white pt-2">
        <div className=" h-fit flex flex-col max-w-[1500px] lg:mx-auto mx-3">
          <div className="w-full flex flex-col md:flex-row justify-center items-center py-16 gap-12">
            <a
              className="h-16"
              href="https://www2.gnb.ca/content/gnb/fr.html?_ga=2.137288052.515093233.1675546814-1675822270.1675446452"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={newbrun}
                alt="header illustration"
                className="w-full h-full object-fill"
              />
            </a>
            <a
              className="h-16"
              href="https://ccnb.ca/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ccnb}
                alt="header illustration"
                className="w-full h-full object-fill"
              />
            </a>
            <a
              className="h-16"
              href="https://travailnb.ca/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={workin}
                alt="header illustration"
                className="w-full h-full object-fill"
              />
            </a>
            <a
              className="h-16"
              href="https://onbcanada.ca/fr/daccueil/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={onb}
                alt="header illustration"
                className="w-full h-full object-fill"
              />
            </a>
            <a
              className="h-16"
              href="https://cenb.com/fr/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={conseil}
                alt="header illustration"
                className="w-full h-full object-fill"
              />
            </a>
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div className="bg-blured py-12">
        <div className="max-w-[1500px] w-full overflow-hidden justify-center lg:mx-auto mx-3 lg:flex-row lg:px-20 h-fit flex flex-col items-center ">
          <img src={aboutIllustration} alt="flex-none illustration" />
          <div className="max-w-[700px] break-words py-12 lg:mx-12 lg:ml-12 ">
            <p className="text-primary text-lg font-semibold uppercase">
              {t("home.section3.about")}
            </p>
            <p className="break-words font-semibold text-2xl pt-4">
              {t("home.section3.contribute")}{" "}
              <span className="text-primary">{t("home.section3.growth")} </span>
              {t("home.section3.and")}
              <span className="text-primary ps-2">
                {t("home.section3.development")}{" "}
              </span>
              {t("home.section3.your")}{" "}
              <span className="text-primary">{t("home.section3.career")} </span>
              .
            </p>
            <p className="pt-4 text-gray-400">
              {t("home.section3.description")}
            </p>
            <div className="pt-6">
              {adminInfo ? (
                <Link to="/target" className="btn-primary-sm">
                  <span>{t("home.section3.start")}</span>
                  <MdOutlineArrowForward className="ml-2 w-4 h-4 text-white" />
                </Link>
              ) : (
                <Link to={`/register`} className="btn-primary-sm">
                  <span>{t("home.section3.start")}</span>
                  <MdOutlineArrowForward className="ml-2 w-4 h-4 text-white" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Section 4 */}
      <div className="bg-white py-14 mb-20 md:mb-0">
        <div className="flex flex-col lg:flex-row gap-4 items-start max-w-[1500px] justify-between mx-auto px-4 ">
          <div className="">
            <p className="font-semibold text-2xl pt-4">
              {t("home.section4.how")}{" "}
              <span className="text-primary">Jumelage CCNB </span>
              {t("home.section4.works")}
            </p>

            <div className="pt-8 flex flex-col gap-6">
              <div className="flex flex-row items-start gap-3">
                <div className="flex-none w-16 h-16 pl-1">
                  <img
                    src={compte}
                    alt="header illustration"
                    width={64}
                    height={64}
                    className="w-full h-full object-cover overflow-hidden"
                  />
                </div>
                <div className="flex flex-col justify-start">
                  <p className="text-lg font-medium">
                    {t("home.section4.account")}
                  </p>
                  <p className="text-gray-400 pr-5 justify-center">
                    {t("home.section4.accountDesc")}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-start gap-3">
                <div className="flex-none w-16 h-16 pl-1">
                  <img
                    src={cv}
                    alt="header illustration"
                    width={64}
                    height={64}
                    className="w-full h-full object-cover overflow-hidden"
                  />
                </div>
                <div className="flex flex-col">
                  <p className="text-lg font-medium">
                    {t("home.section4.student")}
                  </p>
                  <p className="text-gray-400 pr-2">
                    {t("home.section4.studentDesc")}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-start gap-3 pb-5">
                <div className="flex-none w-16 h-16 pl-1">
                  <img
                    src={emploiemploi}
                    alt="header illustration"
                    width={64}
                    height={64}
                    className="w-full h-full object-cover overflow-hidden"
                  />
                </div>
                <div className="flex flex-col">
                  <p className="text-lg font-medium">
                    {t("home.section4.employers")}
                  </p>
                  <p className="text-gray-400 pr-5">
                    {t("home.section4.employersDesc")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-[350px] md:min-h-[500px] h-full w-full justify-center items-center relative">
            <div className="absolute m-auto left-0 right-0 flex flex-col justify-center items-center max-w-[450px] max-h-[450px] md:max-w-full md:max-h-full md:w-[450px] md:h-[450px]">
              <img
                src={stephanie}
                alt="header illustration"
                className="w-full h-full object-cover overflow-hidden"
              />
            </div>
            <div className="absolute bg-white m-auto right-0 w-fit flex flex-col justify-center items-center gap-3 md:gap-6 p-2 md:p-4 shadow-xl rounded-md z-10">
              <p className="font-medium text-xs md:text-xl">
                {t("home.section4.cv")}
              </p>
              <div className="w-fit px-5 py-3 text-xs rounded-md text-gray-500 bg-cprimary-200">
                {t("home.section4.choose")}
              </div>
            </div>
            <div className="ld:mb-8 absolute m-auto bottom-0 right-0 w-fit bg-white flex flex-col justify-center items-center gap-2 md:gap-6 p-2 md:p-4 shadow-xl rounded-md z-10">
              <p className="font-semibold text-xs md:text-xl">
                {t("home.section4.participants")}
              </p>
              <img
                src={participants}
                alt="header illustration"
                className="w-full h-8 md:h-[60px] object-contain overflow-hidden"
              />
            </div>
            <div className=" absolute m-auto top-[200px] md:bottom-0  w-fit bg-white flex flex-col justify-center items-center px-3 md:px-8 py-4 md:py-8 shadow-xl rounded-md z-10">
              <div className="flex flex-col justify-center items-center w-[48px] h-[48px] md:w-[78px] md:h-[78px]">
                <img
                  src={stephanie}
                  alt="header illustration"
                  className="w-full h-full object-cover overflow-hidden"
                />
              </div>
              <p className="font-medium text-xs md:text-xl mt-2">
                {t("home.section4.name")}
              </p>
              <p className="text-gray-400 text-xs mt-1">
                {t("home.section4.comptable")}
              </p>
              <div className="mt-8 w-full md:w-[230px] text-xs">
                <div className="flex flex-row justify-between">
                  <p className="text-gray-400">{t("home.section4.profil")}</p>
                  <p className="text-cprimary-500">60%</p>
                </div>
                <div className="mt-1.5 h-1 w-full bg-gray-300 dark:bg-neutral-600">
                  <div className="h-1 bg-cprimary-700 w-3/4"></div>
                </div>
              </div>
              <div className="mt-6 w-full btn-primary-sm text-xs">
                <span>{t("home.section4.edit")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
