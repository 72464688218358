import React from "react";
import Image from "../../../assets/res/studentregistration3.png";
import Goback from "../../../components/form/Goback";
import StudRegForm3 from "../../../components/signupForm/StudentRegForm/StudRegForm3";
import { useTranslation } from "react-i18next";

const StudReg3 = () => {
  const { t } = useTranslation();
  return (
    <div className="page_cont">
      <div className="flex-1 flex justify-center items-center w-full md:w-1/2">
        <div className=" max-w-lg px-4">
          <Goback link={-1} />
          <div className="flex flex-col mb-5">
            <h3 className="text-2xl text-dark font-bold">
              {t("signUp.register.title")}
            </h3>
            <p className="text-parag text-sm">
              {t("signUp.register.description")}
            </p>
          </div>
          <StudRegForm3 />
        </div>
      </div>
      <div className="flex-1 hidden md:block w-full md:w-1/2">
        <img
          src={Image}
          alt="Register"
          className="w-full h-screen object-center"
        />
      </div>
    </div>
  );
};

export default StudReg3;
