import { lazy } from "react";

// use lazy for better code splitting
const Dashboard = lazy(() => import("../pages/company/dashboard"));
const Student = lazy(() => import("../pages/company/students"));
const Offer = lazy(() => import("../pages/company/offers"));
const OffersTemp = lazy(() => import("../pages/company/offersTemp"));
const Candidate = lazy(() => import("../pages/company/candidate"));
const Faqs = lazy(() => import("../pages/company/faqs"));
const Event = lazy(() => import("../pages/company/events"));
const Profile = lazy(() => import("../pages/company/profile"));
const EventDetails = lazy(() => import("../components/infos/eventDetails"));
const StudentInfo = lazy(() => import("../components/infos/StudentInfo"));
const OfferDetail = lazy(() => import("../pages/company/OfferDetails"));
const CreateOffer = lazy(() => import("../pages/company/createOffer"));
const OfferApplied = lazy(() => import("../pages/company/offerApplied"));
const addOfferStep1 = lazy(() =>
  import("../pages/company/addOffer/addOfferStep1")
);
const addOfferStep2 = lazy(() =>
  import("../pages/company/addOffer/addOfferStep2")
);
const addOfferStep3 = lazy(() =>
  import("../pages/company/addOffer/addOfferStep3")
);
const addOfferFromPDF = lazy(() =>
  import("../pages/company/addOffer/addOfferFromPDF")
);
const UpdateOfferStep1 = lazy(() =>
  import("../pages/company/updateOffer/updateOfferStep1")
);
const UpdateOfferStep2 = lazy(() =>
  import("../pages/company/updateOffer/updateOfferStep2")
);
const UpdateOfferStep3 = lazy(() =>
  import("../pages/company/updateOffer/updateOfferStep3")
);

/*
//  * ⚠ These are internal routes!
//  * They will be rendered inside the app, using the default `containers/LayoutAdmin`.
//  * If you want to add a route to, let's say, a landing page, you should add
//  * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
//  * are routed.
//  *
//  * If you're looking for the links rendered in the SidebarContent, go to
//  * `routes/sidebarAdmin.js`
 */

const routesCompany = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/offer/create",
    component: CreateOffer,
  },
  {
    path: "/offers",
    component: OffersTemp,
  },
  {
    path: "/offer/create/pdf",
    component: addOfferFromPDF,
    exact: true,
    name: "addOfferByPDF",
  },
  {
    path: "/offer/create/form/step_1",
    component: addOfferStep1,
    exact: true,
    name: "addOfferStep1",
  },
  {
    path: "/offer/create/form/step_2",
    component: addOfferStep2,
    exact: true,
    name: "addOfferStep2",
  },
  {
    path: "/offer/create/form/step_3",
    component: addOfferStep3,
    exact: true,
    name: "addOfferStep3",
  },
  {
    path: "/offer/edit/:id/form/step_1",
    component: UpdateOfferStep1,
    exact: true,
    name: "editOfferStep1",
  },
  {
    path: "/offer/:id/applied",
    component: OfferApplied,
    exact: true,
    name: "offerApplied",
  },
  {
    path: "/offer/edit/:id/form/step_2",
    component: UpdateOfferStep2,
    exact: true,
    name: "editOfferStep2",
  },
  {
    path: "/offer/edit/:id/form/step_3",
    component: UpdateOfferStep3,
    exact: true,
    name: "editOfferStep3",
  },
  {
    path: "/offer/:id",
    component: OfferDetail,
  },
  {
    path: "/applied",
    component: Candidate,
  },
  {
    path: "/student",
    component: Student,
  },
  {
    path: "/student/:id",
    component: StudentInfo,
  },
  {
    path: "/events",
    component: Event,
  },
  {
    path: "/events/:id",
    component: EventDetails,
  },
  {
    path: "/faqs",
    component: Faqs,
  },

  {
    path: "/profile",
    component: Profile,
  },
];

export default routesCompany;
