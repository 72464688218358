import requests from "./httpService";

const SchoolServices = {
  getAllSchool: async () => {
    return requests.get(`${process.env.REACT_APP_BASE_API_VERSION}campus`);
  },

  addSchool: async (body) => {
    return requests.post(
      `${process.env.REACT_APP_BASE_API_VERSION}campus`,
      body
    );
  },

  updateSchool: async (body) => {
    return requests.put(
      `${process.env.REACT_APP_BASE_API_VERSION}campus/${body.id}`,
      body.data
    );
  },

  deleteSchool: async (id) => {
    return requests.delete(
      `${process.env.REACT_APP_BASE_API_VERSION}campus/${id}`
    );
  },
};

export default SchoolServices;
