import { lazy } from "react";

// use lazy for better code splitting
const Dashboard = lazy(() => import("../pages/student/dashboard"));
const Company = lazy(() => import("../pages/student/company"));
const Offer = lazy(() => import("../pages/student/offers"));
const Candidate = lazy(() => import("../pages/student/candidate"));
const Faqs = lazy(() => import("../pages/student/faqs"));
const Resource = lazy(() => import("../pages/student/ressource"));
const Event = lazy(() => import("../pages/student/events"));
const Profile = lazy(() => import("../pages/student/profile"));
const EventDetails = lazy(() => import("../components/infos/eventDetails"));
const CompanyInfo = lazy(() => import("../components/infos/CompanyInfo"));
const OfferDetail = lazy(() => import("../components/infos/offerDetail"));

/*
//  * ⚠ These are internal routes!
//  * They will be rendered inside the app, using the default `containers/LayoutAdmin`.
//  * If you want to add a route to, let's say, a landing page, you should add
//  * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
//  * are routed.
//  *
//  * If you're looking for the links rendered in the SidebarContent, go to
//  * `routes/sidebarAdmin.js`
 */

const routesStudent = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/offer",
    component: Offer,
  },
  {
    path: "/offer/:id",
    component: OfferDetail,
  },
  {
    path: "/applied",
    component: Candidate,
  },
  {
    path: "/company",
    component: Company,
  },
  {
    path: "/company/:id",
    component: CompanyInfo,
  },
  {
    path: "/events",
    component: Event,
  },
  {
    path: "/events/:id",
    component: EventDetails,
  },
  {
    path: "/faqs",
    component: Faqs,
  },
  {
    path: "/resource",
    component: Resource,
  },
  {
    path: "/profile",
    component: Profile,
  },
];

export default routesStudent;
