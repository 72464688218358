import React from "react";
import Input from "../../form/Input";
import AppButton from "../../global/AppButton";
import { useTranslation } from "react-i18next";
import ErrorForm from "../../form/Error";
import AppSelect from "../../form/AppSelect";
import useCompanyRegStep2 from "../../../hooks/auth/useCompanyRegStep2";

const CompanyRegForm2 = () => {
  const { t } = useTranslation();
  const {
    formik,
    schoolProgramValue,
    schoolProgramData,
    handleSchoolProgramChange,
    errors,
    loading,
  } = useCompanyRegStep2();

  return (
    <form onSubmit={formik.handleSubmit} enctype="multipart/form-data">
      <div className="my-5 space-y-4">
        <div>
          <Input
            label={t("signUp.enterprise.label.enterpriseLogo")}
            type={"file"}
            className={"file-button"}
            name={"logo"}
            onChange={(e) => formik.setFieldValue("logo", e.target.files[0])}
            onBlur={formik.handleBlur}
            accept={"image/jpg,image/png,image/jpeg"}
          />
          {formik.touched.logo && formik.errors.logo ? (
            <ErrorForm message={formik.errors.logo} />
          ) : null}
        </div>
        <div>
          <AppSelect
            isMultiple={true}
            label={t("signUp.student.label.programmeEtude")}
            placeholder={"Selectionner votre programme d'etude"}
            error={formik.errors.schoolProgram && formik.touched.schoolProgram}
            value={schoolProgramValue}
            options={schoolProgramData}
            handleCampusSelect={handleSchoolProgramChange}
            required={true}
          />
          {formik.touched.schoolProgram && formik.errors.schoolProgram && (
            <ErrorForm message={formik.errors.schoolProgram} />
          )}
        </div>
        <div>
          <Input
            require={true}
            label={t("signUp.enterprise.label.responsibleName")}
            type={"theme"}
            placeholder={t("signUp.enterprise.label.responsibleName")}
            name={"refPerson.refName"}
            value={formik.values.refPerson.refName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched?.refPerson?.refName &&
          formik.errors?.refPerson?.refName ? (
            <ErrorForm message={formik.errors?.refPerson?.refName} />
          ) : null}
        </div>
        <div>
          <div>
            <div className="relative box-border ">
              <Input
                type={"prefix"}
                label={t("signUp.enterprise.label.telephone")}
                placeholder={"000 000 0000"}
                name={"refPerson.refPhoneNumber"}
                value={formik.values.refPerson.refPhoneNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                require={true}
              />
              <div className="absolute z-0 bottom-[16px] left-4 font-semibold text-sm text-gray-600/60 flex justify-center items-center">
                <span className="pt-0.5">+</span>1
              </div>
            </div>
            {formik.touched?.refPerson?.refPhoneNumber &&
            formik.errors?.refPerson?.refPhoneNumber ? (
              <div className="text-xs text-red-600">
                <ErrorForm message={formik.errors.refPerson?.refPhoneNumber} />
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <Input
            require={true}
            label={t("signUp.enterprise.label.emailAddresse")}
            type={"email"}
            placeholder={"example@gmail.com"}
            name={"refPerson.refEmail"}
            value={formik.values.refPerson.refEmail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched?.refPerson?.refEmail &&
          formik.errors?.refPerson?.refEmail ? (
            <ErrorForm message={formik.errors?.refPerson?.refEmail} />
          ) : null}
        </div>
      </div>
      {errors?.length > 0 && (
        <p className="py-3 px-4 mb-3 border border-red-500 text-center text-red-600 font-bold text-xs bg-red-600/20 rounded">
          {errors}
        </p>
      )}
      <div>
        <AppButton
          disabled={loading}
          title={t("signUp.next")}
          type={"submit"}
        />
      </div>
    </form>
  );
};

export default CompanyRegForm2;
