import axios from "axios";
import Cookies from "js-cookie";
import { USER } from "../pref";
import {useNavigate} from "react-router-dom";
import {AdminContext} from "../context/AdminContext";
import {useContext} from "react";

const instance = axios.create({
  // ${process.env.REACT_APP_BASE_API_VERSION}
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
  timeout: 20000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeoutErrorMessage: "Le serveur met trop de temps à répondre, réessayez plus tard.",
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let adminInfo;
  if (Cookies.get(USER)) {
    adminInfo = JSON.parse(Cookies.get(USER));
  }

  return {
    ...config,
    headers: {
      authorization: adminInfo ? `Bearer ${adminInfo.accessToken}` : null,
      "Access-Control-Allow-Origin": "*",
    },
  };
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log("error.response.status ", error.response.status);

    if (parseInt(error.response.status) === 401) {
      console.log("try logout")
      const { dispatch } = useContext(AdminContext);
      const navigate = useNavigate();
      dispatch({ type: "USER_LOGOUT" });
      Cookies.remove(USER);
      navigate("/home");
    }
    return Promise.reject(error);
  }
);

const responseBody = (response) => response;

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: (url, body) => instance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body) =>
    instance.delete(url, { data: body }).then(responseBody),
};

export default requests;
