import React from "react";
import Goback from "../../../components/form/Goback";
import StudRegForm1 from "../../../components/signupForm/StudentRegForm/StudRegForm1";
import Image from "../../../assets/res/login.png";
import { useTranslation } from "react-i18next";

const StudReg1 = () => {
  const { t } = useTranslation();
  return (
    <div className="page_cont ">
      <div className="flex-1 flex justify-center items-center w-full md:w-1/2 ">
        <div className="w-full max-w-lg px-4">
          <Goback link={"/register"} />
          <div className="flex flex-col mb-5">
            <h3 className="text-2xl text-dark font-bold">
              {t("signUp.register.title")}
            </h3>
            <p className="mt-1 ml-[1px] text-parag text-sm">
              {t("signUp.register.description")}
            </p>
          </div>
          <StudRegForm1 />
        </div>
      </div>
      <div className="flex-1 hidden md:block w-full md:w-1/2">
        <img src={Image} alt="Register" className="w-full h-screen " />
      </div>
    </div>
  );
};

export default StudReg1;
