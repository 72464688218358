import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import LoginImage from "../../assets/res/login.png";
import LoginForm from "../../components/auth/LoginForm";
import { Link } from "react-router-dom";
import SwitchLanguage from "../../components/form/SwitchLanguage";
import useTranslate from "../../hooks/useTranslate";

const Login = () => {
  const { t } = useTranslation();
  const {currentLanguageCode, handleLanguageChange} = useTranslate();

  return (
    <div className="page_cont">
      <div className="flex-1 flex justify-center items-center relative w-full md:w-1/2">
        <div className="max-w-lg w-full px-4">
          <div className="flex flex-col mb-5">
            <h3 className="text-2xl text-dark font-bold">{t("login.title")}</h3>
            <p className="text-parag text-sm">{t("login.description")}</p>
          </div>
          <div>
            <LoginForm />
          </div>
          <div>
            <p className="mt-2 text-parag text-xs text-center">
              {t("login.noAccount")}
              <Link to="/register">
                <span className="text-customOrange hover:underline underline-offset-2 font-semibold pl-1 cursor-pointer">
                  {t("login.register")}
                </span>
              </Link>
            </p>
          </div>
        </div>
        <SwitchLanguage
            currentLanguageCode={currentLanguageCode}
            handleLanguageChange={handleLanguageChange}
        />
      </div>
      <div className="flex-1 w-full md:w-1/2 hidden md:block">
        <img
          src={LoginImage}
          alt="login"
          className="w-full h-screen object-center"
        />
      </div>
    </div>
  );
};

export default Login;
