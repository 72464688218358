import React from "react";

const Main = ({ children }) => {
  return (
    <main className="h-full overflow-y-auto px-2 md:px-6">
      <div className="container grid mx-auto">{children}</div>
    </main>
  );
};

export default Main;
