import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import useAsync from "../useAsync";
import SchoolServices from "../../services/SchoolServices";
import ProgramsServices from "../../services/ProgramServices";
import { REGISTER_STUDENT_STEP_2 } from "../../redux/Constants/StudentRegisterConstant";

const useStudentRegStep2 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // init search param
  const [searchParams, setSearchParams] = useSearchParams({
    photo: "",
    school: "",
    schoolProgram: "",
  });
  const photo = searchParams.get("photo");
  const school = searchParams.get("school");
  const schoolProgram = searchParams.get("schoolProgram");

  // formik initial var
  const initialValues = {
    photo: photo || "",
    studyProgram: {
      id: schoolProgram || "",
      campus: {
        id: school || "",
      },
    },
  };

  // formik submit
  const onSubmit = (e) => {
    console.log(e);
    setSearchParams(
      (prev) => {
        prev.set("photo", formik.values.photo);
        prev.set("school", formik.values.studyProgram.campus.id);
        prev.set("schoolProgram", formik.values.studyProgram.id);
        return prev;
      },
      { replace: true }
    );
    navigate("/register/student/step_3");
    dispatch({
      type: REGISTER_STUDENT_STEP_2,
      data: {
        ...e,
        school: schoolValue,
        schoolProgram: schoolProgramValue,
      },
    });
  };

  // formik validation Schema
  const validationSchema = yup.object({
    photo: yup
      .mixed()
      .test("FILE_SIZE", "Image must be less than 5MB", function (value) {
        if (value === "undefined" || value)
          return value && value.size <= 1024 * 1024 * 5;
        else {
          return true;
        }
      })
      .test("FILE_TYPE", "Image must be JPEG, PNG or JPG", function (value) {
        if (value === "undefined" || value)
          return (
            value &&
            (value.type === "image/jpg" ||
              value.type === "image/jpeg" ||
              value.type === "image/png")
          );
        else {
          return true;
        }
      })
      .nullable(),
    studyProgram: yup.object().shape({
      id: yup
        .string()
        .required(t("signUp.student.validationMessage.schoolProgram")),
      campus: yup.object().shape({
        id: yup.string().required(t("signUp.student.validationMessage.school")),
      }),
    }),
  });

  // formik init
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    loading: loadingSchool,
    data: schoolsData,
    error: errorSchool,
  } = useAsync(SchoolServices.getAllSchool);

  const {
    loading: loadingProgram,
    data: programsData,
    error: errorSchoolProgram,
  } = useAsync(ProgramsServices.getAllPrograms);

  // for school
  const [schoolValue, setSchoolValue] = useState(null);
  const [schoolData, setSchoolData] = useState([]);
  const handleSchoolChange = (value) => {
    setSchoolValue(value);

    if (schoolProgramValue !== null) {
      handleSchoolProgramChange(null);
    }

    const _schoolProgramData = programsData?.data?.filter(
      (it) => it.campus.id === value?.value
    );
    const _schoolProgramDataValue = _schoolProgramData?.map((it) => ({
      label: it.name,
      value: it.id,
    }));
    setSchoolProgramData(_schoolProgramDataValue);

    //set schoolProgram from url
    if (
      schoolProgram !== "" &&
      schoolProgram !== null &&
      schoolProgramValue === null
    ) {
      const item = _schoolProgramData?.find(
        (item) => item.id === parseInt(schoolProgram)
      );
      handleSchoolProgramChange({
        value: item.id,
        label: item.name,
      });
    }
  };

  useEffect(() => {
    if (schoolsData?.data) {
      setSchoolData(
        schoolsData?.data?.map((item, i) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [schoolsData]);

  // form school program
  const [schoolProgramValue, setSchoolProgramValue] = useState(null);
  const [schoolProgramData, setSchoolProgramData] = useState([]);
  const handleSchoolProgramChange = (value) => {
    setSchoolProgramValue(value);
  };

  // formik retrieve school value when select
  useEffect(() => {
    formik.setFieldValue(
      "studyProgram.campus.id",
      schoolValue === null ? "" : schoolValue.value
    );
  }, [schoolValue]);

  // formik retrieve schoolProgram value when select
  useEffect(() => {
    formik.setFieldValue(
      "studyProgram.id",
      schoolProgramValue === null ? "" : schoolProgramValue.value
    );
  }, [schoolProgramValue]);

  // retrieve province & city from url
  useEffect(() => {
    if (
      school !== "" &&
      school !== null &&
      schoolsData?.data &&
      programsData?.data
    ) {
      //set school
      const item = schoolsData?.data?.find(
        (item) => item.id === parseInt(school)
      );
      handleSchoolChange({
        value: item.id,
        label: item.name,
      });
    }
  }, [
    school !== "" && school !== null && schoolsData?.data && programsData?.data,
  ]);

  return {
    formik,
    handleSchoolChange,
    handleSchoolProgramChange,
    schoolData,
    schoolProgramData,
    schoolValue,
    schoolProgramValue,
  };
};

export default useStudentRegStep2;
