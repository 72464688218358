import requests from "./httpService";

const AdminServices = {
  login: async (body) => {
    return requests.post(`/login`, body);
  },

  registerStudent: async (body) => {
    return requests.post(
      `${process.env.REACT_APP_BASE_API_VERSION}students/register`,
      body
    );
  },

  uploadStudentImage: async (id, image) => {
    return requests.put(
      `${process.env.REACT_APP_BASE_API_VERSION}users/${id}/picture`,
      image
    );
  },

  registerCompany: async (body) => {
    return requests.post(
      `${process.env.REACT_APP_BASE_API_VERSION}entreprises/register`,
      body
    );
  },

  verifyEmail: async (body) => {
    return requests.get(
      `${process.env.REACT_APP_BASE_API_VERSION}users/exist/${body}`
    );
  },
};

export default AdminServices;
