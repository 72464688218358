import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Goback = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="mb-4" onClick={() => navigate(-1)}>
      <div
        className={`text-customOrange text-sm cursor-pointer ${props.className}`}
      >
        <ArrowBack fontSize="small" />
        <span className="pl-1">{t("goback")}</span>
      </div>
    </div>
  );
};

export default Goback;
