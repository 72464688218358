import "./App.css";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter,
  createBrowserRouter,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import Login from "./pages/auth/Login";
import LayoutAdmin from "./layout/LayoutAdmin";
import React, { useContext } from "react";
import { AdminContext } from "./context/AdminContext";
import Home from "./pages/home";
import Page404 from "./pages/404";
import RegisterRoute from "./routes/routeAuth";
import PrivateRoute from "./components/login/PrivateRoute";
import routesAdmin from "./routes/routeAdmin";
import { ADMIN, COMPANY, STUDENT } from "./pref";
import routesStudent from "./routes/routeStudent";
import routesCompany from "./routes/routeCompany";

function App() {
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <AccessibleNavigationAnnouncer />
        <Routes>
          <Route path="/login" Component={Login} />
          <Route path="/home" Component={Home} />

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<LayoutAdmin />}>
              {(adminInfo?.roles[0]?.name === ADMIN
                ? routesAdmin
                : adminInfo?.roles[0].name === STUDENT
                ? routesStudent
                : routesCompany
              ).map((route, index) => {
                return route.component ? (
                  <Route
                    key={index}
                    path={`${route.path}`}
                    element={<route.component />}
                  />
                ) : null;
              })}
            </Route>
          </Route>

          {RegisterRoute.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                name={route.name}
                Component={route.component}
              />
            ) : null;
          })}

          <Route path="*" Component={Page404} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
