import React from "react";
import Input from "../../form/Input";
import AppButton from "../../global/AppButton";
import { useTranslation } from "react-i18next";
import ErrorForm from "../../form/Error";
import useCompanyRegStep1 from "../../../hooks/auth/useCompanyRegStep1";

const CompanyRegForm1 = () => {
  const { t } = useTranslation();
  const { formik, loading, error } = useCompanyRegStep1();

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="my-5">
        <div className="mb-3">
          <Input
            require={true}
            type={"theme"}
            label={t("signUp.enterprise.label.enterpriseName")}
            placeholder={t("signUp.enterprise.label.enterpriseName")}
            name={"enterpriseName"}
            value={formik.values.enterpriseName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.enterpriseName && formik.errors.enterpriseName ? (
            <ErrorForm message={formik.errors.enterpriseName} />
          ) : null}
        </div>
        <div className="mb-3">
          <Input
            require={true}
            type={"email"}
            label={t("signUp.enterprise.label.emailAddresse")}
            placeholder={"example@gmail.com"}
            name={"email"}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <ErrorForm message={formik.errors.email} />
          ) : null}
        </div>
        <div className="mb-3">
          <Input
            require={true}
            type={"theme"}
            label={t("signUp.enterprise.label.address")}
            placeholder={t("signUp.enterprise.label.address")}
            name={"address.address"}
            value={formik.values.address.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.address && formik.errors.address ? (
            <ErrorForm message={formik.errors.address.address} />
          ) : null}
        </div>
        <div className="mb-3">
          <Input
            type={"url"}
            label={t("signUp.enterprise.label.website")}
            placeholder={"site web"}
            name={"website"}
            value={formik.values.website}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      {error?.length > 0 && (
        <p className="py-3 px-4 mb-3 border border-red-500 text-center text-red-600 font-bold text-xs bg-red-600/20 rounded">
          {error}
        </p>
      )}
      <div>
        <AppButton
          disabled={loading}
          title={t("signUp.next")}
          type={"submit"}
        />
      </div>
    </form>
  );
};

export default CompanyRegForm1;
