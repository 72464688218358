import { MdOutlineArrowForward } from "react-icons/md";

import { FaBars } from "react-icons/fa";

import { MdClose } from "react-icons/md";

import logo from "../../assets/ico/logo.png";
import { useTranslation } from "react-i18next";
import useHome from "../../hooks/useHome";

const Header = () => {
  const { t } = useTranslation();

  const {
    adminInfo,
    showMobileNav,
    handleShow,
    openPage,
    currentLanguageCode,
    handleLanguageChange,
  } = useHome();

  return (
    <>
      <div className="w-full lg:absolute z-10 text-white bg-primary">
        <div className="max-w-[1500px] mx-auto">
          <nav className="flex flex-row justify-between items-center h-16 px-8">
            {/*Website Logo*/}
            <a href="/" className="mt-0.5">
              <img src={logo} alt="jumet" className="w-52" />
            </a>

            {/*Primary Navbar items*/}
            <div className="hidden lg:block">
              <div className="flex flex-row justify-center items-center text-sm lg:text-md gap-4">
                {adminInfo ? (
                  <>
                    <a
                      href="/target"
                      className="border-2 rounded-lg flex flex-row justify-center items-center border-white px-8 py-2.5 bg-transparent hover:bg-black hover:bg-opacity-5 focus:ring-0 transition duration-150 ease-in-out"
                    >
                      <span>{t("Mon compte")}</span>
                      <MdOutlineArrowForward className="ml-2 w-4 h-4 text-white" />
                    </a>
                    <button
                      onClick={() => {
                        console.log(
                          "currentLanguageCode ",
                          currentLanguageCode
                        );
                        if (
                          currentLanguageCode === "en" ||
                          currentLanguageCode === "en-GB"
                        ) {
                          handleLanguageChange("fr");
                        } else if (
                          currentLanguageCode === "fr" ||
                          currentLanguageCode === "fr-FR"
                        ) {
                          handleLanguageChange("en");
                        }
                      }}
                      className="lg:ml-4 w-12 h-12 rounded-full border-2 flex justify-center items-center uppercase hover:bg-black hover:bg-opacity-5 active:bg-gray-500 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                    >
                      {currentLanguageCode === "en" ? "fr" : "en"}
                    </button>
                  </>
                ) : (
                  <>
                    <a href="/login" className="hover:underline">
                      {t("home.header.login")}
                    </a>
                    <a
                      href="/register"
                      className="border-2 rounded-lg flex flex-row justify-center items-center border-white px-8 py-2.5 bg-transparent hover:bg-black/10 focus:ring-0 transition duration-150 ease-in-out"
                    >
                      <span>{t("home.header.start")}</span>
                      <MdOutlineArrowForward className="text-white ml-2 w-4 h-4" />
                    </a>
                    <button
                      onClick={() => {
                        if (
                          currentLanguageCode === "en" ||
                          currentLanguageCode === "en-GB"
                        ) {
                          handleLanguageChange("fr");
                        } else if (
                          currentLanguageCode === "fr" ||
                          currentLanguageCode === "fr-FR"
                        ) {
                          handleLanguageChange("en");
                        }
                      }}
                      className="lg:ml-4 w-12 h-12 rounded-full border-2 flex justify-center items-center uppercase hover:bg-black/10 active:bg-gray-500 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                    >
                      {currentLanguageCode === "en" ? "fr" : "en"}
                    </button>
                  </>
                )}
              </div>
            </div>

            {/*/Mobile menu button*/}
            <div className="flex flex-row justify-center items-center gap-3 lg:hidden ">
              <button
                onClick={() => {
                  if (currentLanguageCode === "en") {
                    handleLanguageChange("fr");
                  } else if (currentLanguageCode === "fr") {
                    handleLanguageChange("en");
                  }
                }}
                className="w-8 h-8 rounded-full border-2 text-xs flex justify-center items-center uppercase hover:bg-black/10 active:bg-gray-500 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              >
                {currentLanguageCode === "en-GB" ? "fr" : "en"}
              </button>
              <button
                className="text-white flex justify-center outline-none mobile-menu-button border-none focus:outline-none active:outline-none"
                onClick={handleShow}
              >
                {showMobileNav ? (
                  <MdClose className="w-6 h-6 " />
                ) : (
                  <FaBars className="w-6 h-6 focus:outline-none active:outline-none" />
                )}
              </button>
            </div>
          </nav>

          {/*Mobile menu*/}
          <div className={showMobileNav ? "block transition-all" : "hidden"}>
            <div className="bg-primary text-white z-50 py-4 flex items-center justify-between px-4 lg:hidden transition-all">
              <a
                href={`/login`}
                className="flex-1 w-full flex flex-wrap items-center "
              >
                <p className="w-full text-sm border-2 rounded border-white px-4 py-1.5 flex justify-center">
                  <span>{t("home.header.login")}</span>
                </p>
              </a>

              <a
                href="/register"
                className="flex-1 w-full flex items-center justify-between mx-4"
              >
                <p className="w-full text-sm border-2 rounded border-white px-4 py-1.5 flex justify-center">
                  <span>{t("home.header.start")}</span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
