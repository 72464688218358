import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import Input from "../../form/Input";
import AppButton from "../../global/AppButton";
import { useTranslation } from "react-i18next";
import ErrorForm from "../../form/Error";
import AppSelect from "../../form/AppSelect";
import useStudentRegStep1 from "../../../hooks/auth/useStudentRegStep1";

const StudRegForm1 = (props) => {
  const { t } = useTranslation();

  const {
    formik,
    provinceValue,
    provinceData,
    handleProvinceChange,
    cityValue,
    cityData,
    handleCityChange,
    loading,
    error,
  } = useStudentRegStep1();

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full my-5 space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
            {/* firstname */}
            <div>
              <Input
                label={t("signUp.student.label.firstname")}
                type={"theme"}
                placeholder={t("signUp.student.label.firstname")}
                name={"firstName"}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                require={true}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <ErrorForm message={formik.errors.firstName} />
              )}
            </div>

            {/* lastname */}
            <div>
              <Input
                label={t("signUp.student.label.lastname")}
                type={"theme"}
                placeholder={t("signUp.student.label.lastname")}
                name={"lastName"}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                require={true}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <ErrorForm message={formik.errors.lastName} />
              )}
            </div>
          </div>

          {/* email address */}
          <div>
            <Input
              type={"email"}
              label={t("signUp.student.label.emailAddress")}
              placeholder={"example@monccnb.com"}
              name={"email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              require={true}
            />
            {formik.touched.email && formik.errors.email && (
              <ErrorForm message={formik.errors.email} />
            )}
          </div>

          {/* location */}
          <div className="flex flex-col md:flex-row gap-2">
            <div className="flex-1">
              <AppSelect
                label={t("signUp.student.label.province")}
                placeholder={t("signUp.student.label.selectProvince")}
                error={
                  formik.errors?.adress?.province &&
                  formik.touched?.adress?.province
                }
                value={provinceValue}
                options={provinceData}
                handleCampusSelect={handleProvinceChange}
                required={true}
              />
              {formik.touched?.adress?.province &&
                formik.errors?.adress?.province && (
                  <ErrorForm message={formik.errors?.adress?.province} />
                )}
            </div>
            <div className="flex-1">
              <AppSelect
                label={t("signUp.student.label.city")}
                placeholder={t("signUp.student.label.selectCity")}
                error={
                  formik.errors?.adress?.city && formik.touched?.adress?.city
                }
                value={cityValue}
                options={cityData}
                handleCampusSelect={handleCityChange}
                required={true}
              />
              {formik.touched?.adress?.city && formik.errors?.adress?.city && (
                <ErrorForm message={formik.errors?.adress?.city} />
              )}
            </div>
          </div>

          {/* Phone number */}
          <div>
            <div className="relative box-border ">
              <Input
                type={"prefix"}
                label={t("signUp.student.label.phoneNumber")}
                placeholder={"000 000 0000"}
                name={"phoneNumber"}
                value={formik.values.phoneNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                require={true}
              />
              <div className="absolute z-0 bottom-[16px] left-4 text-sm text-black flex justify-center items-center">
                <span className="pt-0.5">+</span>1
              </div>
            </div>
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <ErrorForm message={formik.errors.phoneNumber} />
            ) : null}
          </div>
        </div>
        {error?.length > 0 && (
          <p className="py-3 px-4 mb-3 border border-red-500 text-center text-red-600 font-bold text-xs bg-red-600/20 rounded">
            {error}
          </p>
        )}
        <div>
          <AppButton
            disabled={loading}
            title={t("signUp.next")}
            type={"submit"}
          />
        </div>
      </form>
    </div>
  );
};

export default StudRegForm1;
