import React, { useContext } from 'react'
import SidebarContent from './SidebarContent'
import {SidebarContext} from "../../context/SidebarContext";
import { Transition } from 'react-transition-group';
import {GrFormClose} from "react-icons/gr/"

function MobileSidebar() {
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext)

  if(!isSidebarOpen){
    return
  }

  return (
      <Transition>
        <div className="">
          <Transition
              enter="transition ease-in-out duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in-out duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <GrFormClose onClick={closeSidebar} className="hidden"/>
          </Transition>

          <Transition
              enter="transition ease-in-out duration-150"
              enterFrom="opacity-0 transform -translate-x-20"
              enterTo="opacity-100"
              leave="transition ease-in-out duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 transform -translate-x-20"
          >
            <aside className="fixed inset-y-0 z-50 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 lg:hidden">
              <SidebarContent />
            </aside>
          </Transition>
        </div>
      </Transition>
  )
}

export default MobileSidebar
