import React, { useContext } from "react";
import { NavLink, Route, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { IoLogOutOutline } from "react-icons/io5";
import logo from "../../assets/ico/logo_primary.png";

import { AdminContext } from "../../context/AdminContext";
import SidebarSubMenu from "./SidebarSubMenu";
// import SidebarSubMenu from "SidebarSubMenu";
import { useTranslation } from "react-i18next";
import sidebarAdmin from "../../routes/sidebarAdmin";
import {ADMIN, COMPANY, STUDENT, USER} from "../../pref";
import sidebarStudent from "../../routes/sidebarStudent";
import sidebarCompany from "../../routes/sidebarCompany";

const SidebarContent = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AdminContext);
  const navigate = useNavigate();

  const { state } = useContext(AdminContext);
  const { adminInfo } = state;

  const handleLogOut = () => {
    dispatch({ type: "USER_LOGOUT" });
    Cookies.remove(USER);
    navigate("/home");
  };

  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <div className="w-full flex justify-center items-center mt-3">
        <img src={logo} alt="dashtar" className="w-48" />
      </div>
      {/*<a className=" text-gray-900 dark:text-gray-200" href="/dashboard">*/}
      {/*  {mode === "dark" ? (*/}
      {/*    <img src={logoLight} alt="dashtar" width="135" className="pl-6" />*/}
      {/*  ) : (*/}
      {/*    <img src={logoDark} alt="dashtar" width="135" className="pl-6" />*/}
      {/*  )}*/}
      {/*</a>*/}
      <ul className="mt-12 mx-4">
        {(adminInfo?.roles[0]?.name === ADMIN
                ? sidebarAdmin
                : (adminInfo?.roles[0].name === STUDENT ? sidebarStudent : sidebarCompany)
        ).map((route) =>
          route.routes ? (
            <SidebarSubMenu route={route} key={route.name} />
          ) : (
            <li className="relative" key={route.name}>
              <NavLink
                // exact={true}
                to={route.path}
                target="_self"
                className={(navData) =>
                  navData.isActive
                    ? "px-4 py-4 rounded-md inline-flex items-center w-full text-sm text-primary bg-primary bg-opacity-10 hover:text-primary font-semibold"
                    : "px-4 py-4 rounded-md inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:bg-primary hover:bg-opacity-10 hover:font-semibold hover:text-primary"
                }
              >
                <route.icon className="w-5 h-5" aria-hidden="true" />
                <span className="ml-4">{t(`${route.name}`)}</span>
              </NavLink>
            </li>
          )
        )}
      </ul>
      <span className="lg:fixed bottom-0 px-6 py-6 w-64 mx-auto relative mt-3 block">
        <button onClick={handleLogOut} className="w-full">
          <span className="flex items-center">
            <IoLogOutOutline className="mr-3 text-lg focus:outline-none" />
            <span className="text-sm">{t("LogOut")}</span>
          </span>
        </button>
      </span>
    </div>
  );
};

export default SidebarContent;
