import React, { useContext, Suspense, useEffect, lazy } from "react";
import {useLocation, Outlet} from "react-router-dom";

import Main from "./Main";
import Header from "../components/admin/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import { SidebarContext } from "../context/SidebarContext";
import ThemeSuspense from "../components/theme/ThemeSuspense";

const Layout = () => {
  const { isSidebarOpen, closeSidebar, navBar } = useContext(SidebarContext);
  let location = useLocation();

  // console.log('routes',routes)

  useEffect(() => {
    closeSidebar();
  }, [location]);

  return (
    <div
      className={`flex h-screen bg-gray-50 dark:bg-gray-900 ${
        isSidebarOpen && "overflow-hidden"
      }`}
    >
      {navBar && <Sidebar />}

      <div className="flex flex-col flex-1 w-full overflow-hidden">
        <Header />
        <Main>
          {/*<div className="text-4xl text-red-600">dsqdjsql</div>*/}
          <Suspense fallback={<ThemeSuspense />}>
            <Outlet/>
          </Suspense>
        </Main>
      </div>
    </div>
  );
};

export default Layout;
