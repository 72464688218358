import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { COMPANY_REGISTER_STEP_1 } from "../../redux/Constants/CompanyRegisterConstant";
import AdminServices from "../../services/AuthServices";

const useCompanyRegStep1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({
    enterpriseName: "",
    email: "",
    website: "",
    address: "",
    city: "",
    country: "",
  });
  const enterpriseName = searchParams.get("enterpriseName");
  const email = searchParams.get("email");
  const address = searchParams.get("address");
  const website = searchParams.get("website");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const initialValues = {
    enterpriseName: enterpriseName || "",
    email: email || "",
    address: {
      address: address || "",
      city: "",
      country: "Canada",
    },
    website: website || "",
  };
  const validationSchema = yup.object({
    email: yup
      .string()
      .email()
      .required(t("signUp.enterprise.validationMessage.email")),
    enterpriseName: yup
      .string()
      .required(t("signUp.enterprise.validationMessage.enterpriseName")),
    website: yup.string(),
    address: yup.object().shape({
      address: yup
        .string()
        .required(t("signUp.enterprise.validationMessage.address")),
    }),
  });
  const onSubmit = (e) => {
    setLoading(true);
    setSearchParams(
      (prev) => {
        prev.set("enterpriseName", formik.values.enterpriseName);
        prev.set("email", formik.values.email);
        prev.set("address", formik.values.address.address);
        prev.set("website", formik.values.website);
        return prev;
      },
      { replace: true }
    );
    AdminServices.verifyEmail(formik.values.email)
      .then((res) => {
        setLoading(false);
        if (res.data === true) {
          setError("Sorry! Email already exist...");
        } else {
          dispatch({ type: COMPANY_REGISTER_STEP_1, data: e });
          navigate("/register/company/step_2");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err ? err?.response?.data?.message : err?.message);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    loading,
    error,
  };
};

export default useCompanyRegStep1;
