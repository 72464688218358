import React, { useState } from "react";
import Input from "../form/Input";
import AppButton from "../global/AppButton";
import { useTranslation } from "react-i18next";
import { FaEyeSlash, FaEye } from "react-icons/fa6";
import ErrorForm from "../form/Error";
import useLoginSubmit from "../../hooks/auth/useLoginSubmit";

const LoginForm = () => {
  const { t } = useTranslation();

  const { formik, showPassword, togglePassword, error, removeError, loading } =
    useLoginSubmit();

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-4">
          {/* email */}
          <div>
            <Input
              required={true}
              label={t("login.label.email")}
              type={"theme"}
              placeholder={"Email"}
              name={"email"}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);

                if (error) {
                  removeError();
                }
              }}
              // onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <ErrorForm message={formik.errors.email} />
            ) : null}
          </div>

          {/*  password */}
          <div>
            <div className="relative box-border">
              <Input
                required={true}
                label={t("login.label.password")}
                type={showPassword ? "suffix" : "password"}
                name={"password"}
                placeholder={"Mot de passe"}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue("password", e.target.value);

                  if (error) {
                    removeError();
                  }
                }}
                // className="pr-8"
              />
              <div className="absolute z-50 bottom-3 right-2 hover:bg-gray-100 w-10 h-8 rounded-full text-gray-500 flex justify-center items-center">
                <button
                  className="w-1/3 focus:outline-none focus:ring-0"
                  type="button"
                  onClick={togglePassword}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <ErrorForm message={formik.errors.password} />
            ) : null}
          </div>
        </div>

        {/*  forgot password*/}
        <div className="flex justify-end hover:underline items-center px-1.5 my-6">
          <a href="/login" className="text-dark text-sm">
            {t("login.passwordForgot")}
          </a>
        </div>

        {error?.length > 0 && (
          <p className="py-3 px-4 mb-3 border border-red-500 text-center text-red-600 font-bold text-xs bg-red-600/20 rounded">
            {error}
          </p>
        )}

        {/*  Login btn*/}
        <div className="mb-3">
          <AppButton
            type="submit"
            disabled={loading}
            title={t("login.button")}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
