import { lazy } from "react";
import Register from "../pages/auth/register";
import StudReg1 from "../pages/auth/studentRegister/StudReg1";
import StudReg2 from "../pages/auth/studentRegister/StudReg2";
import StudReg3 from "../pages/auth/studentRegister/StudReg3";
import CompReg1 from "../pages/auth/companyRegister/CompReg1";
import CompReg2 from "../pages/auth/companyRegister/CompReg2";
import CompReg3 from "../pages/auth/companyRegister/CompReg3";

const RegisterRoute = [
  {
    name: "register",
    exact: true,
    path: "/register",
    component: Register,
  },
  {
    name: "studReg1",
    exact: true,
    path: "/register/student",
    component: StudReg1,
  },
  {
    name: "studReg2",
    exact: true,
    path: "/register/student/step_2",
    component: StudReg2,
  },
  {
    name: "studReg3",
    exact: true,
    path: "/register/student/step_3",
    component: StudReg3,
  },
  {
    name: "compReg1",
    exact: true,
    path: "/register/company",
    component: CompReg1,
  },
  {
    name: "compReg2",
    exact: true,
    path: "/register/company/step_2",
    component: CompReg2,
  },
  {
    name: "compReg3",
    exact: true,
    path: "/register/company/step_3",
    component: CompReg3,
  },
];

export default RegisterRoute;
