import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/lang/en.json'
import fr from './locales/lang/fr.json'

const resources = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        // resources: {
        //     fr: { translation: translationFr },
        //     en: { translation: translationEn },
        // },
        debug: true,
        //lng: "en",
        fallbackLng: 'fr',
        nonExplicitSupportedLngs: true,

        interpolation: {
            escapeValue: false,
        },
        detection: {
            //order: ['path', 'cookie', 'htmlTag'],
            caches: ['cookie'],
        },
    });
