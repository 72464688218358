import React from "react";

const CustomCard = (props) => {
  return (
    <div
      className={`w-full rounded-lg shadow-md p-5 hover:shadow-sm cursor-pointer transition-all ${props.className}`}
    >
      <p className="font-semibold py-2">{props.title}</p>
      <div className="flex justify-end mt-4 hover:translate-x-1 transition-all">
        {props.icon}
      </div>
    </div>
  );
};

export default CustomCard;
