import {
  REGISTER_STUDENT_STEP_1,
  REGISTER_STUDENT_STEP_2,
  REGISTER_STUDENT_STEP_3,
} from "../Constants/StudentRegisterConstant";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  adress: {},
  telephone: "",
  studyProgram: {},
  photo: {},
  password: "",
  username: "",
  active: true,
  notLocked: true,
};

export const StudentAddReducers = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_STUDENT_STEP_1:
      return {
        ...state,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        adress: action.data.adress,
        email: action.data.email,
        username: action.data.email,
        telephone: action.data.phoneNumber,
      };
    case REGISTER_STUDENT_STEP_2:
      return {
        ...state,
        photo: action.data.photo,
        studyProgram: action.data.studyProgram,
        firstName: state.firstName,
        lastName: state.lastName,
        adress: state.adress,
        email: state.email,
        username: state.username,
        telephone: state.telephone,
      };
    case REGISTER_STUDENT_STEP_3:
      return {
        ...state,
        password: action.password,
      };
    default:
      return state;
  }
};
