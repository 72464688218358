import React from "react";

const SwitchLanguage = ({currentLanguageCode, handleLanguageChange}) => {
  return (
      <div className="absolute bottom-5">
          <button onClick={() => (currentLanguageCode === "en" || currentLanguageCode === "en-GB") ? handleLanguageChange("fr") : null }
                  className={["p-2 focus:outline-none border-r-2", currentLanguageCode === "fr" ? "font-bold text-customOrange" : "hover:text-primary hover:font-bold" ].join(" ")}>
              FR
          </button>
          <button onClick={() => (currentLanguageCode === "fr" || currentLanguageCode === "fr-FR") ? handleLanguageChange("en") : null }
                  className={["p-2 focus:outline-none", currentLanguageCode === "en" ? "font-bold text-customOrange" : "hover:text-primary hover:font-bold" ].join(" ")}>
              EN
          </button>
      </div>
  )
};

export default SwitchLanguage;
