import {
  COMPANY_REGISTER_STEP_1,
  COMPANY_REGISTER_STEP_2,
  COMPANY_REGISTER_STEP_3,
} from "../Constants/CompanyRegisterConstant";

const initialState = {
  name: "",
  email: "",
  website: "",
  address: {},
  logo: {},
  schoolProgram: "",
  telephone: "",
  password: "",
  refPerson: {},
  firstName: "",
  lastName: "",
};

export const CompanyAddReducers = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_REGISTER_STEP_1:
      return {
        ...state,
        name: action.data.enterpriseName,
        email: action.data.email,
        website: action.data.website,
        address: action.data.address,
        firstName: action.data.enterpriseName,
        lastName: action.data.enterpriseName,
      };
    case COMPANY_REGISTER_STEP_2:
      return {
        ...state,
        logo: action.data.logo,
        refPerson: action.data.refPerson,
        schoolProgram: action.data.schoolProgram,
        telephone: action.data.phoneNumber,
        name: state.name,
        email: state.email,
        website: state.website,
        address: state.address,
        firstName: state.firstName,
        lastName: state.lastName,
      };
    case COMPANY_REGISTER_STEP_3:
      return {
        ...state,
        password: action.password,
      };
    default:
      return state;
  }
};
