import {
  ADD_OFFER_STEP_1,
  ADD_OFFER_STEP_2,
  ADD_OFFER_STEP_3,
  GET_OFFER,
} from "../Constants/OfferAddConstants";

const initialState = {
  title: "",
  shortDescription: "",
  description: "",
  skills: [],
  minRate: 0,
  maxRate: 0,
  expirationDate: "",
  offerType: [],
  entrepriseId: "",
  status: "VISIBLE",
};

export const OfferAddReducers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OFFER_STEP_1:
      return {
        ...state,
        title: action.data.title,
        shortDescription: action.data.shortDescription,
        description: action.data.description,
      };
    case ADD_OFFER_STEP_2:
      return {
        ...state,
        skills: action.totalSkills,
      };
    case ADD_OFFER_STEP_3:
      return {
        ...state,
        minRate: action.data.minRate,
        maxRate: action.data.maxRate,
        expirationDate: action.data.expirationDate,
        offerType: action.data.offerType,
        entrepriseId: action.data.entrepriseId,
      };
    case GET_OFFER:
      return {
        title: action.offer.title,
        shortDescription: action.offer.shortDescription,
        description: action.offer.description,
        skills: action.offer.skills,
        minRate: action.offer.minRate,
        maxRate: action.offer.maxRate,
        expirationDate: action.offer.expirationDate,
        offerType: action.offer.offerTypes,
        entrepriseId: action.offer.entrepriseId,
        status: action.offer.status,
      };
    default:
      return state;
  }
};
