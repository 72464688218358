import React from "react";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CustomCard from "../../components/card/CustomCard";
import Student from "../../assets/res/studentregistration1.png";
import { useTranslation } from "react-i18next";
import logo from "../../assets/ico/logo_primary.png";
import SwitchLanguage from "../../components/form/SwitchLanguage";
import useTranslate from "../../hooks/useTranslate";

const Register = () => {
  const { t } = useTranslation();
  const { currentLanguageCode, handleLanguageChange } = useTranslate();

  return (
    <div className="min-w-screen h-screen bg-white flex flex-col md:flex-row">
      <div className="flex-1 flex justify-center items-center relative">
        <div>
          <div className="w-full flex justify-center items-center">
            <img src={logo} alt="dashtar" className="w-48" />
          </div>

          <div className="w-full max-w-lg  mt-8 mb-8 mx-2">
            <div className="mb-8 ">
              <Link to={"/register/student"}>
                <CustomCard
                  title={t("signUp.subtitle1")}
                  icon={<ArrowForward />}
                  className={
                    "hover:bg-primary hover:text-white text-parag transition"
                  }
                />
              </Link>
            </div>
            <div className="mb-2">
              <Link to={"/register/company"}>
                <CustomCard
                  title={t("signUp.subtitle2")}
                  icon={<ArrowForward />}
                  className={
                    "hover:bg-primary hover:text-white text-parag transition"
                  }
                />
              </Link>
            </div>
          </div>
          <div>
            <p className="text-parag text-sm text-center">
              {t("signUp.alreadyHaveAccount")}
              <Link to={"/login"}>
                <span className="text-customOrange hover:underline underline-offset-2 font-semibold pl-1 cursor-pointer">
                  {t("signUp.connect")}
                </span>
              </Link>
            </p>
          </div>
        </div>

        <SwitchLanguage
          currentLanguageCode={currentLanguageCode}
          handleLanguageChange={handleLanguageChange}
        />
      </div>
      <div className="flex-1 hidden md:block">
        <img
          src={Student}
          alt="student"
          className="w-full h-screen object-center"
        />
      </div>
    </div>
  );
};

export default Register;
