import {
  BsBriefcaseFill,
  BsFillCalendar3WeekFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import { FaUser, FaUserTie } from "react-icons/fa";
import { ImHome } from "react-icons/im";
import { IoIosPaper, IoMdSettings } from "react-icons/io";

/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/routeAdmin.js`
 */
const sidebarCompany = [
  {
    path: "/", // the url
    icon: ImHome, // icon
    name: "nav.company.dashboard", // name that appear in Sidebar
  },
  {
    path: "/offer/create", // the url
    icon: BsBriefcaseFill, // icon
    name: "nav.company.createOffer", // name that appear in Sidebar
  },
  {
    path: "/offers", // the url
    icon: BsBriefcaseFill, // icon
    name: "nav.company.offer", // name that appear in Sidebar
  },
  {
    path: "/applied", // the url
    icon: FaUser, // icon
    name: "nav.company.candidate", // name that appear in Sidebar
  },
  {
    path: "/student", // the url
    icon: FaUserTie, // icon
    name: "nav.company.student", // name that appear in Sidebar
  },
  {
    path: "/events", // the url
    icon: BsFillCalendar3WeekFill, // icon
    name: "nav.company.events", // name that appear in Sidebar
  },
  {
    path: "/faqs", // the url
    icon: BsFillQuestionCircleFill, // icon
    name: "nav.company.faqs", // name that appear in Sidebar
  },
  {
    path: "/profile", // the url
    icon: IoMdSettings, // icon
    name: "nav.company.profile", // name that appear in Sidebar
  },
];

export default sidebarCompany;
