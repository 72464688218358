import React from "react";
import Input from "../../form/Input";
import AppSelect from "../../form/AppSelect";
import AppButton from "../../global/AppButton";
import { useTranslation } from "react-i18next";
import ErrorForm from "../../form/Error";
import useStudentRegStep2 from "../../../hooks/auth/useStudentRegStep2";

const StudRegForm2 = ({ link }) => {
  const { t } = useTranslation();
  console.log({ link });

  const {
    formik,
    handleSchoolChange,
    handleSchoolProgramChange,
    schoolValue,
    schoolProgramValue,
    schoolData,
    schoolProgramData,
  } = useStudentRegStep2();

  return (
    <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
      <div className="my-5 flex flex-col gap-4">
        <div>
          <Input
            require={true}
            label={t("signUp.student.label.photo")}
            type={"file"}
            className={"file-button"}
            accept="image/png, image/jpg, image/jpeg"
            name={"photo"}
            onChange={(e) => formik.setFieldValue("photo", e.target.files[0])}
            onBlur={formik.handleBlur}
          />
          {formik.touched.photo && formik.errors.photo ? (
            <ErrorForm message={formik.errors.photo} />
          ) : null}
        </div>
        <div>
          <AppSelect
            label={t("signUp.student.label.etablissement")}
            placeholder={t("signUp.student.label.selectCampus")}
            error={
              formik.errors?.studyProgram?.campus?.id &&
              formik.touched?.studyProgram?.campus?.id
            }
            value={schoolValue}
            options={schoolData}
            handleCampusSelect={handleSchoolChange}
            required={true}
          />
          {formik.touched?.studyProgram?.campus?.id &&
            formik.errors?.studyProgram?.campus?.id && (
              <ErrorForm message={formik.errors?.studyProgram?.campus?.id} />
            )}
        </div>
        <div>
          <AppSelect
            label={t("signUp.student.label.programmeEtude")}
            placeholder={t("signUp.student.label.selectStudyProgram")}
            error={
              formik.errors?.studyProgram?.id &&
              formik.touched?.studyProgram?.id
            }
            value={schoolProgramValue}
            options={schoolProgramData}
            handleCampusSelect={handleSchoolProgramChange}
            required={true}
          />
          {formik.touched?.studyProgram?.id &&
            formik.errors?.studyProgram?.id && (
              <ErrorForm message={formik.errors?.studyProgram?.id} />
            )}
        </div>
      </div>
      <div>
        <AppButton title={t("signUp.next")} type={"submit"} />
      </div>
    </form>
  );
};

export default StudRegForm2;
