import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { AdminContext } from "../context/AdminContext";
import {ADMIN, LANGUAGE, USER} from "../pref";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useFormik} from "formik";
import cookies from "js-cookie";
import {SidebarContext} from "../context/SidebarContext";

const useTranslate = () => {
  const { handleLanguageChange} = useContext(SidebarContext);
  const currentLanguageCode = cookies.get(LANGUAGE) || "fr";
  console.log("currentLanguageCode ", currentLanguageCode)

  return {
    currentLanguageCode,
    handleLanguageChange,
  };
};

export default useTranslate;
