import { useContext, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { AdminContext } from "../context/AdminContext";
import cookies from "js-cookie";
import {SidebarContext} from "../context/SidebarContext";
import {LANGUAGE} from "../pref";

const useLoginSubmit = () => {
  // for session
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;

  // for language
  const currentLanguageCode = cookies.get(LANGUAGE) || "fr";
  // console.log("currentLanguageCode ", currentLanguageCode)
  const { handleLanguageChange} = useContext(SidebarContext);

  const navigate = useNavigate();
  const [showMobileNav, setShowMobileNav] = useState(false)

  const handleShow = () => {
    setShowMobileNav(o => !o)
  }

  const openPage = (route) => {
    window.scrollTo(0, 0)
    navigate(route)
  }

  return {
    adminInfo,
    showMobileNav,
    handleShow,
    openPage,
    currentLanguageCode,
    handleLanguageChange
  };
};

export default useLoginSubmit;
