import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import canada from "../../assets/json/canada.json";
import { REGISTER_STUDENT_STEP_1 } from "../../redux/Constants/StudentRegisterConstant";
import AdminServices from "../../services/AuthServices";

const useStudentRegStep1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // init search param
  const [searchParams, setSearchParams] = useSearchParams({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    province: "",
    city: "",
  });
  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const email = searchParams.get("email");
  const phoneNumber = searchParams.get("phoneNumber");
  const province = searchParams.get("province");
  const city = searchParams.get("city");

  // formik initial var
  const initialValues = {
    email: email || "",
    firstName: firstName || "",
    lastName: lastName || "",
    phoneNumber: phoneNumber || "",
    adress: {
      city: city || "",
      province: province || "",
      country: "Canada",
    },
  };

  // formik validation
  const validationSchema = yup.object({
    email: yup
      .string()
      .email()
      .required(t("signUp.student.validationMessage.email"))
      .test(
        "matchCheck",
        "Email must end with @monccnb.ca or @monccnb.com",
        (value) => {
          const regex = /@monccnb\.ca$/;
          const regexs = /@monccnb\.com$/;
          return regex.test(value) || regexs.test(value);
        }
      ),
    firstName: yup
      .string()
      .required(t("signUp.student.validationMessage.firstName")),
    lastName: yup
      .string()
      .required(t("signUp.student.validationMessage.lastName")),
    phoneNumber: yup
      .number()
      .required(t("signUp.student.validationMessage.phoneNumber")),
    adress: yup.object().shape({
      province: yup
        .string()
        .required(t("signUp.student.validationMessage.province")),
      city: yup.string().required(t("signUp.student.validationMessage.city")),
    }),
  });

  // formik submit
  const onSubmit = (e) => {
    const data = { ...e, telephone: `+1${e.phoneNumber}` };
    setSearchParams(
      (prev) => {
        prev.set("email", formik.values.email);
        prev.set("firstName", formik.values.firstName);
        prev.set("lastName", formik.values.lastName);
        prev.set("phoneNumber", formik.values.phoneNumber);
        prev.set("province", formik.values.adress.province);
        prev.set("city", formik.values.adress.city);
        return prev;
      },
      { replace: true }
    );
    setLoading(true);
    AdminServices.verifyEmail(formik.values.email)
      .then((res) => {
        setLoading(false);
        if (res.data.data) {
          setError("Sorry! Email already exist...");
        } else {
          navigate("/register/student/step_2");
          dispatch({ type: REGISTER_STUDENT_STEP_1, data });
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err ? err?.response?.data?.message : err?.message);
      });
  };

  // formik init
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  // for province
  const [provinceValue, setProvinceValue] = useState(null);
  const [provinceData, setProvinceData] = useState(
    canada.map((item, i) => {
      return {
        value: item.province,
        label: item.province,
      };
    })
  );
  const handleProvinceChange = (value) => {
    setProvinceValue(value);

    if (cityValue !== null) {
      handleCityChange(null);
    }

    let cities = canada.find((item) => item.province === value?.value)?.city;
    setCityData(
      cities?.map((item, i) => {
        return {
          value: item,
          label: item,
        };
      })
    );
  };

  // for city
  const [cityValue, setCityValue] = useState(null);
  const [cityData, setCityData] = useState([]);
  const handleCityChange = (value) => setCityValue(value);

  // formik retrieve city value when select
  useEffect(() => {
    formik.setFieldValue(
      "adress.city",
      cityValue === null ? "" : cityValue.value
    );
  }, [cityValue]);

  // formik retrieve province value when select
  useEffect(() => {
    formik.setFieldValue(
      "adress.province",
      provinceValue === null ? "" : provinceValue.value
    );
  }, [provinceValue]);

  // retrieve province & city from url
  useEffect(() => {
    if (province !== "" && province !== null) {
      //set province
      const item = canada.find((item) => item.province === province);
      handleProvinceChange({
        value: item.province,
        label: item.province,
      });

      //set city list
      let cities = canada.find((item) => item.province === province).city;
      setCityData(
        cities.map((item, i) => {
          return {
            value: item,
            label: item,
          };
        })
      );

      //set state value
      if (city !== "" && city !== null) {
        const item = cities.find((item) => item === city);
        handleCityChange({
          value: item,
          label: item,
        });
      }
    }
  }, [province]);

  return {
    formik,
    provinceValue,
    provinceData,
    handleProvinceChange,
    cityValue,
    cityData,
    handleCityChange,
    loading,
    error,
  };
};

export default useStudentRegStep1;
