import React from "react";
import Input from "../../form/Input";
import AppButton from "../../global/AppButton";
import { useTranslation } from "react-i18next";
import ErrorForm from "../../form/Error";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import useCompanyRegStep3 from "../../../hooks/auth/useCompanyRegStep3";
import { useDispatch } from "react-redux";
import { COMPANY_REGISTER_STEP_3 } from "../../../redux/Constants/CompanyRegisterConstant";

const CompanyRegForm3 = () => {
  const { t } = useTranslation();
  const {
    formik,
    isError,
    showPassword,
    togglePassword,
    toggleConfirmPassword,
    showConfirmPassword,
    confirmPassword,
    setConfirmPassword,
    setIsError,
    error,
    loading,
  } = useCompanyRegStep3();
  const dispatch = useDispatch();

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="my-5">
        <div className=" mb-3">
          <div>
            <div className="relative box-border">
              <Input
                require={true}
                label={t("signUp.student.label.password")}
                type={showPassword ? "suffix" : "password"}
                name={"password"}
                placeholder={t("signUp.student.label.password")}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  formik.setFieldValue("password", e.target.value);
                  dispatch({
                    type: COMPANY_REGISTER_STEP_3,
                    password: e.target.value,
                  });
                }}
              />
              <div className="absolute z-50 bottom-3 right-2 hover:bg-gray-100 w-10 h-8 rounded-full text-gray-500 flex justify-center items-center">
                <button
                  className="w-1/3 focus:outline-none focus:ring-0"
                  type="button"
                  onClick={togglePassword}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <ErrorForm message={formik.errors.password} />
            ) : null}
          </div>
        </div>
        <div className=" mb-3">
          <div>
            <div className="relative box-border">
              <Input
                require={true}
                label={t("signUp.student.label.confirmPassword")}
                type={showConfirmPassword ? "suffix" : "password"}
                placeholder={t("signUp.student.label.confirmPassword")}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);

                  if (isError) {
                    setIsError(false);
                  }
                }}
              />
              <div className="absolute z-50 bottom-3 right-2 hover:bg-gray-100 w-10 h-8 rounded-full text-gray-500 flex justify-center items-center">
                <button
                  className="w-1/3 focus:outline-none focus:ring-0"
                  type="button"
                  onClick={toggleConfirmPassword}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="mb-5 text-xs">
        {t("signUp.student.accountCreation")}
        <span className="text-customOrange px-1">
          {t("signUp.student.usageCondition")}
        </span>
        &
        <span className="text-customOrange pl-1">
          {t("signUp.student.confidentiality")}
        </span>
      </p>
      {isError && (
        <p
          className="py-3 px-4 mb-3 border border-red-500 text-red-500 font-bold text-xs bg-red-600/20 rounded"
          onClick={() => setIsError(false)}
        >
          {t("noMatchPassword")}
        </p>
      )}
      {error?.length > 0 && (
        <p className="py-3 px-4 mb-3 border border-red-500 text-center text-red-600 font-bold text-xs bg-red-600/20 rounded">
          {error}
        </p>
      )}
      <div>
        <AppButton
          disabled={loading}
          title={t("signUp.register.button")}
          type={"submit"}
        />
      </div>
    </form>
  );
};

export default CompanyRegForm3;
